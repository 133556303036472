import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Check} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    stepper: {
        background: "transparent",
    },

    stepperLabel: {
        background: theme.palette.secondary,
        color: "white"
    }

}));

function MyStepper(prop, ref) {
    const {labels, renderStep} = prop
    const classes = useStyles();
    const [step, setStep] = useState(0);

    useImperativeHandle(ref, () => ({
        handleNext (msg) {
            setStep((prevActiveStep) => prevActiveStep + 1);
        }
    }), [])

    return (
        <>
            <Stepper activeStep={step} alternativeLabel className={classes.stepper}>
                {labels.map((label) => (
                    <Step key={label} className={classes.stepperLabel}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {renderStep(step)}
            </div>
        </>
    )
}

export default forwardRef(MyStepper)


const useQontoStepIconStyles = makeStyles((theme) => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: theme.palette.secondary.light
    },
    circle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: theme.palette.secondary.light,
        zIndex: 1,
        fontSize: 18,
    },
}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}