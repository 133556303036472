import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {connectWallet, getETHAddress, sign} from "../io/Wallet";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getAssets} from "../io/OpenSea";
import FormHelperText from "@material-ui/core/FormHelperText";
import MyStepper from "../components/Stepper";
import {getDrawingUrl} from "../io/Bots";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    },

    stepper: {
        background: "transparent",
    },

    stepperLabel: {
        background: theme.palette.secondary,
        color: "white"
    },

    form: {
        padding: theme.spacing(5),
        alignContent: "center"
    },

    drawing: {
        padding: theme.spacing(5),
        width: '80%',
        height: '80%',
    },

    input: {
        margin: theme.spacing(1)
    },

    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }

}));

export default function Generating() {

    const classes = useStyles();
    const [data, setData] = useState({});
    const stepper = useRef();

    const handleFillForm = (signature, payload) => {
        setData({signature, payload});
        stepper.current.handleNext();
    }

    const getStepComponent = (curr) => {
        switch (curr) {
            case 0:
                return <ConnectWalletStep onConnectedSuccess={() => stepper.current.handleNext()}/>
            case 1:
                return <FillFormStep onFormFull={(sign, payload) => handleFillForm(sign, payload)}/>
            case 2:
                return <GenerateStep data={data}/>
            default:
                return ""
        }
    }

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>🎨 Generating</Typography>
                    <Typography className={classes.headitem}>
                        Singularty use web3 (dApp) technologies to generate your artwork.
                        Therefore you need to connect your wallet to generate your unique and authenticated artwork.
                    </Typography>

                    <MyStepper ref={stepper} renderStep={getStepComponent}
                               labels={["Connect Wallet", "Fill Form", "Generate"]}/>

                </Grid>
            </Grid>
        </div>
    );
}

export function ConnectWalletStep({onConnectedSuccess = f => f}) {
    const classes = useStyles();

    const [status, setStatus] = useState("disconnected");
    const handler = function () {
        connectWallet(res => {
            if (res) {
                onConnectedSuccess();
            } else {
                setStatus("error")
            }
        });

    }

    return (<>
        <Paper variant="outlined" elevation={3} className={classes.form}>
            <Typography className={classes.input}>To prove your ownership, this form will be signed by your wallet.
                Therefore, firstly connect
                your wallet (the same that content NFT) please.</Typography>
            <Button variant="contained" color="primary" onClick={handler} className={classes.input}>Connect Metamask
                Wallet</Button>
            {status === "error" ? <Typography color="error">Can't connect your wallet...</Typography> : <></>}
        </Paper>

    </>);

}

export function FillFormStep({onFormFull = f => f}) {
    const classes = useStyles();

    const [account, setAccount] = useState("");
    const [assets, setAssets] = useState([])
    const [token, setToken] = useState("");

    const submit = function () {
        if (validate()) {
            const date = new Date().toISOString().slice(0, 7);
            const payload = `${token}::${account}::${date}`;
            sign(account, payload, (sign => onFormFull(sign, payload)));
        }
    }

    function validate() {
        return assets !== undefined && assets.length !== 0;
    }

    useEffect(() => {
        getETHAddress().then(addr => getAssets("BOT", res => {
            setAccount(addr[0])
            setAssets(res)
        }, addr[0]))
    }, [])

    return (<>
        <Paper variant="outlined" elevation={3} className={classes.form}>
            <form noValidate onSubmit={() => submit()} style={{color: "white"}}>
                <TextField
                    disabled
                    variant="outlined"
                    id="account"
                    label="Account"
                    value={account}
                    fullWidth
                    className={classes.input}/>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={!validate()}
                    fullWidth>
                    <InputLabel id="token-label">NFT</InputLabel>
                    <Select
                        labelId="token-label"
                        id="token"
                        value={token}
                        className={classes.input}
                        onChange={(e) => setToken(e.target.value)}
                        label="NFT">
                        {assets.map(asset => <MenuItem key={asset.token_id}
                                                       value={asset.token_id}>{asset.name}</MenuItem>)}
                    </Select>
                    {validate() ? "" : <FormHelperText>Any NFT available</FormHelperText>}
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.input}
                    disabled={!validate()}
                    onClick={() => submit()}>Sign Data</Button>
            </form>
        </Paper>
    </>)
}

export function GenerateStep({data}) {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);

    const {signature, payload} = data
    console.log(data);
    const signb64 = Buffer.from(signature.substring(2), 'hex').toString('base64');
    const mes = JSON.stringify({message: payload, signature: signb64})
    const mesb64 = Buffer.from(mes, 'utf-8').toString('base64');
    const url = getDrawingUrl(mesb64)

    function download() {
        const link = document.createElement("a");
        link.download = `drawing.svg`;
        link.href = url+ "?download=True";
        link.click();
    }

    return (<>
        <div className={classes.button}>
            <Button variant={"outlined"} color={"secondary"} disabled={!loaded} onClick={download} style={{marginRight: "10px"}}>Download</Button>
        </div>
        <Paper variant="outlined" elevation={3} className={classes.drawing}>
            { loaded ? <></> : <img with="100%" src="/static/loading.gif" alt="loading..."/> }
            <img width="100%" src={url} onLoad={() => setLoaded(true)} alt="loading..."/>
        </Paper>

    </>)
}
