import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Button from "@material-ui/core/Button";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import {useHistory} from "react-router";
import {getAssets} from "../io/OpenSea";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    }
}));

export default function Humans() {
    const classes = useStyles();

    const nbCols = Math.max(1, Math.floor(3 * window.innerWidth / window.innerHeight));
    const height = window.innerWidth / nbCols;

    const [artworks, setArtworks] = useState([])
    const history = useHistory()


    useEffect(() => {
        getAssets("ARTIST", res => setArtworks(res))
    }, [])

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>👩 Human Artworks</Typography>
                    <Typography className={classes.headitem}>
                        Singularty is a home for various artists including many skills and styles. Discover them ! If you own one of their artworks, then claim your shipping !
                    </Typography>
                    <Button variant="outlined" color="secondary" onClick={() => history.push("/shipping")} className={classes.headitem}>Claim your Shipping</Button>

                    <Typography variant="h5" className={classes.headitem}>The Three mandatories parts of ownership</Typography>

                    <table style={{width: "100%"}}>
                        <thead>
                        <th style={{width: "20%"}}></th>
                        <th style={{width: "40%"}}>Singularty 👩 NFT</th>
                        <th style={{width: "40%"}}>Other NFT for 🐒</th>
                        </thead>
                        <tbody>

                        <tr><td>&nbsp;</td></tr>
                        <tr><td rowSpan="2" className={classes.parts}><b>Abusus</b></td><td colSpan="2"><i>The right to own something, resell it or delete it</i></td></tr>
                        <tr><td>✅ only for you</td><td>✅ only for you</td></tr>

                        <tr><td>&nbsp;</td></tr>
                        <tr><td rowSpan="2" className={classes.parts}><b>Usus</b></td><td colSpan="2"><i>The right to use your asset as you want</i></td></tr>
                        <tr><td>✅ only for you</td><td>❌ for everyone</td></tr>

                        <tr><td>&nbsp;</td></tr>
                        <tr><td rowSpan="2" className={classes.parts}><b>Fructus</b></td><td colSpan="2"><i>The right to make money with your asset use</i></td></tr>
                        <tr><td>✅ enable</td><td>❌ impossible</td></tr>
                        </tbody>
                    </table>

                </Grid>
            </Grid>

{/*            <Tabs
                style={{background: "none"}}
                value={value}
                indicatorColor="secondary"
                textColor="white"
                onChange={handleChange}
                aria-label="disabled tabs example"
                centered
            >
                <Tab label="P"/>
                <Tab label="F"/>
                <Tab label="J"/>
            </Tabs>*/}

            <div style={{padding: 30}}/>

            <GridList cellHeight={height} cols={nbCols} className={classes.gridList}>
                {artworks.map((tile) => (
                    <GridListTile key={tile.token_id} cols={1} rows={1} onClick={() => window.open(tile.permalink)}>
                        <img src={tile.image_url} alt={tile.name} />
                        <GridListTileBar
                            title={tile.name}
                            subtitle={<span>{tile.sell_orders === null ? "" : "Ξ" + tile.sell_orders.current_price / 100000000000000000}</span>}
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );

}