import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import {recover} from "../io/Wallet";
import {getAsset} from "../io/OpenSea";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    },

    form: {
        padding: theme.spacing(5),
        alignContent: "center"
    },

    spacing: {
        margin: theme.spacing(1)
    }
}));

export default function ShippingVerify() {
    const classes = useStyles();

    const [rawData, setRawData] = useState("");
    const [data, setData] = useState();
    const [asset, setAsset] = useState();
    const [status, setStatus] = useState("no-data no-sign no-token no-owner")

    function verify(text) {
        setRawData(text);
        try {
            setData(JSON.parse(text));
            setStatus("no-sign no-token no-owner")

            recover(JSON.stringify(data.message), data.hash).then(addr => {
                console.log(addr)
                console.log(data.message.wallet)
                console.log(addr.upper === data.message.wallet.upper)
                if (addr.upper === data.message.wallet.upper) {
                    setStatus("no-token no-owner");
                    getAsset(data.message.token, (a => {
                        if (a !== undefined) {
                            setAsset(a);
                            const owner = data.message.wallet; // TODO a.owner.address;
                            setStatus(data.message.wallet === owner ? "ok" : "no-owner")
                        }
                    }))
                }
            });

        } catch (e) {
            setStatus("no-data no-sign no-token no-owner")
            console.log(e)
        }
    }

    function showAsset() {
        if (status === "ok" && asset !== undefined) {
            return (<>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center">
                    <Grid item xs={6}>
                        <img src={asset.image_url} width="100%" alt="artwork"/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.spacing} variant="h2">{asset.name}</Typography>
                        <Typography className={classes.spacing} variant="subtitle1">{asset.description}</Typography>

                        <Typography className={classes.spacing} variant="h5">&#10;Claimed</Typography>
                        <Typography className={classes.spacing} variant="caption">by <code>{data.message.wallet}</code></Typography><Typography/>
                        <Typography className={classes.spacing}>the {new Date(data.message.time).toDateString()}</Typography>


                        <Typography className={classes.spacing} variant="h5">&#10;Send to</Typography><Typography/>
                        <Typography className={classes.spacing}>{data.message.name}</Typography><Typography/>
                        <Typography className={classes.spacing}>{data.message.address}</Typography><Typography/>

                        <Typography className={classes.spacing}>{data.message.zipcode} {data.message.city}</Typography>
                        <Typography/><Typography/>

                        <Typography variant="body">{data.message.comment}</Typography>



                    </Grid>
                </Grid>

            </>);
        }
    }

    function showLabel(name) {
        const valid = !status.includes(`no-${name}`)
        return (
            <Typography color={valid ? "black" : "error"} display="inline">
                {valid ? `\t✅${name} valid` : `\t❌ ${name} invalid`}
            </Typography>
            );
    }

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={8} className={classes.head}>
                    <Paper className={classes.form}>
                        <TextField
                            variant="outlined"
                            id="data"
                            label="Data"
                            value={rawData}
                            className={classes.spacing}
                            multiline
                            rows={7}
                            onChange={(e) => verify(e.target.value)} fullWidth/>
                        {showLabel("data")}
                        {showLabel("sign")}
                        {showLabel("token")}
                        {showLabel("owner")}
                        <div className={classes.spacing}>{showAsset()}</div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}