import {makeStyles} from "@material-ui/core/styles";
import {getAccountFromPrivKey} from "../io/Wallet";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useEffect, useState} from "react";
import {getAssets} from "../io/OpenSea";
import {GenerateStep} from "./Generating";
import {useLocation} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(10)
    },

    stepper: {
        background: "transparent",
    },

    stepperLabel: {
        background: theme.palette.secondary,
        color: "white"
    },

    form: {
        padding: theme.spacing(5),
        alignContent: "center"
    },

    drawing: {
        padding: theme.spacing(5),
        width: 1000,
        height: 1000,
    },

    input: {
        margin: theme.spacing(1)
    }

}));

export default function QrcodeParser() {
    const classes = useStyles();
    const [data, setData] = useState({});

    const search = useLocation().search;
    const key = new URLSearchParams(search).get('key');
    const {address, sign} = getAccountFromPrivKey(key);

    const date = new Date().toISOString().slice(0, 7);

    useEffect(() => {
        getAssets("BOT", (res) => {
            const {token_id} = res[0];

            const payload = `${token_id}::${address}::${date}`;
            const {signature} = sign(payload);

            setData({signature, payload})
        }, address);
        // eslint-disable-next-line
    }, [key])

    function printAddress(a) {
        let printable = "0x"
        for (let i=0; i<10; i++) {
            printable += " " + a.substring(2+i*4, 6+i*4);
        }
        return printable;
    }

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>🎨 Generate from QRCode</Typography>
                    <Typography variant={"caption"} className={classes.headitem}>
                        {printAddress(address)}
                    </Typography>

                    {data.signature !== undefined  ? <GenerateStep data={data}/> : <></>}
                </Grid>
            </Grid>
        </div>
    );
}
