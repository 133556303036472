import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import React from "react";
import {Avatar, Card, CardActions, CardContent, CardHeader} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "96vh",
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    head: {
        margin: theme.spacing(3)
    },

    card: {
        textAlign: "left",
        background: "none",
        color: "white",
        boxShadow: "none",
        border: "1px solide while",
    },

    underline: {
        background: "white",
        color: theme.palette.primary.light,
        fontWeight: "bold"
    },

    title: {
        padding: theme.spacing(8)
    },

    left: {
        textAlign: "left",
        padding: theme.spacing(2)
    },

    justify: {
        textAlign: "justify",
        padding: theme.spacing(2),
    },

    image: {
        width: "30%"
    }
}));

export default function Home() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container style={{width: "100%"}}
                  justify="space-around"
                  alignItems="center">
                <Grid item xs={12} sm={8}>
                    <div className={classes.title}>
                        <Typography variant="h2">Skeptical NFT for skeptical people</Typography>

                    </div>
                </Grid>
            </Grid>

            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Card style={{textAlign: "left"}}>
                        <CardHeader
                            avatar={
                                <Avatar src="https://www.jolain.net/assets/home/profils.png"></Avatar>
                            }
                            title={<Typography variant="h5">Why Singularty.io ?</Typography>}
                        />
                        <CardContent>
                            <Typography>I am in crypto for a while. And I am desperate to see monkey NFT selling for the
                                price
                                of an appartment, whereas these "artworks" can be downloaded and used by everyone. Do
                                you ? <br/><br/>
                                For no crypto people, it's worst. They look the NFT market like a huge scam with
                                headache. You need
                                to buy ethereum (where ?), to set in a metamask to buy NFT on opensean thanks to the
                                web3
                                technologie and metaverse<br/><br/>
                                I create Singularty.io to fix these issues and democratize NFT for the most skeptical
                                people.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={3}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={<>
                                <Typography variant="h5">Like a physical artwork,<br/>Singularty <b>can be buy by CC and
                                    you receive
                                    a physical object</b></Typography>
                            </>}
                        />
                        <CardContent>
                            <Typography>
                                We manage all technical things for you. At this end, you just need to buy a <b>Singulart
                                metal card wallet</b> on Singularty e-shop. It's a metal card, where we securely engrave
                                your wallet data containing your NFT. You just need to scan the QRCode on it to access
                                to your Artwork. No installation or crypto knowledge is needed.<br/><br/>
                                If you understand web3 and hold ethereum, Singularty NFT is directly available on
                                opensea marketplace.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="secondary">Visit Shop</Button>
                            <Button variant="outlined" color="secondary">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={<Typography variant="h5">Like a physical artwork<br/>Singularty <b>is unique and held
                                only by you</b></Typography>}
                        />
                        <CardContent>
                            <Typography>
                                It is a big target for Singularty. Sell NFT with unique and private artwork. To achive
                                this goal, Singularty NFTs don't contain artworks. It's a pass to let a Singularty
                                Painter Bot generate your artwork. Like that your artwork is know and hold only by
                                you.<br/><br/>
                                Even if you resell your NFT, you still hold your artwork (don't forget to download it).
                                And the next owner will have a different generated Singularty Artwork.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="secondary">Visit Shop</Button>
                            <Button variant="outlined" color="secondary">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={3}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={<Typography variant="h5">Like a physical artwork<br/>Singularty <b>artwork is signed,
                                everyone can check its provenance</b></Typography>}
                        />
                        <CardContent>
                            <Typography>
                                Each Singularty Artwork files has a cryptographics signature. It's a secure tag to
                                authenticate each Artwork like a generated Singularty Artwork. This tags work off-chain
                                and offline even if singularty.io is down or close.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="secondary">Visit Shop</Button>
                            <Button variant="outlined" color="secondary">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>

        </Box>);
}
