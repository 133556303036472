export const BACKEND = window.location.href.includes("localhost") && false
    ? "http://localhost:5000"
    : "https://singulartyfucjzaf8-server.functions.fnc.fr-par.scw.cloud";

export function getDrawingUrl(signature) {
    return `${BACKEND}/${signature}/drawing.svg`;
}

export function verify(drawing, callback) {
    fetch(`${BACKEND}/verify`, {
        method: 'POST',
        body: drawing
    }).then(res => res.text().then(body => callback(res.status === 200, body)))
}
