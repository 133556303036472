import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {verify} from "../io/Bots";
import {Alert, TextField} from "@mui/material";
import {useLocation} from "react-router";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    },

    form: {
        padding: theme.spacing(5),
        alignContent: "center"
    },

    input: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(10)
    }

}));


export function RobotsVerify() {
    const classes = useStyles();
    const search = useLocation().search;


    const [url, setUrl] = useState("");
    const [status, setStatus] = useState("")
    const [owner, setOwner] = useState("")
    const [file, setFile] = useState("");

    const submit = function () {
        setStatus("loading")
        verify(file, (res, o) => {
            setStatus(res ? "success" : "fail");
            setOwner(o);
        });
    }

    const validate = function () {
        return url !== "" || file !== "";
    }

    const upload = function (e) {
        const file = e.target.files.item(0);
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onloadend = (e) => {
            setFile(reader.result);
        }
    }

    const download = function () {
        fetch(url)
            .then(res => res.text())
            .then(res => {
                setFile(Buffer.from(res).toString("utf-8"));
                setStatus("")
            })
            .catch(e => setStatus("error-download"))
    }

    const renderStatus = function () {
        console.log(owner)
        switch(status) {
            case "success":
                return <Alert severity="success">TRUE Singularty Generated Artwork.<br/>Generated by: <b>{owner}</b></Alert>
            case "fail":
                return <Alert severity="error">FALSE Singularty Generated Artwork</Alert>
            case "loading":
                return <Alert severity="info">Loading...</Alert>
            case "error-download":
                return <Alert severity="warning">Error during downloading file</Alert>
            default:
                return <div></div>
        }
    }


    useEffect(() => {
        const url = new URLSearchParams(search).get('url');
        setUrl(url)
        download()
        // eslint-disable-next-line
    }, [search])

    return (<>
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>🔐 Robot Authentication</Typography>
                    <Typography className={classes.headitem}>
                        Singularty generated artworks are authenticated !
                        That means artworks embedded a cryptographic tag inside them.
                        The tag enable everyone to verify singularty prevenance and which ethereum address originally generated artwork.
                        <br/><br/>
                        Form below is ready to use tag reader. To check easy singularty provenance and which ethereum address generated this artwork.
                        <br/><br/>
                        You can directly set the link: <br/> <code>https://singularty.io/verify-robots?url=artwork_url</code>


                    </Typography>
                    <Paper variant="outlined" elevation={3} className={classes.form}>
                        <form noValidate onSubmit={() => submit()} style={{color: "white"}}>
                            <TextField
                                variant="outlined"
                                id="url"
                                label="Get Image Url"
                                value={url}
                                onChange={(e) => {
                                    setUrl(e.target.value);
                                    download();
                                }}
                                fullWidth
                                className={classes.input}/>

                            <label>
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    content="image/svg+xml"
                                    type="file"
                                    onChange={upload}
                                />

                                <Button color="primary" variant="outlined" component="span" fullWidth style={{marginTop: 10}}>
                                    Or Upload your Artwork
                                </Button>
                            </label>

                            {file === "" ? <div></div> :
                                <img width="100%" alt="drawing"
                                     src={"data:image/svg+xml;base64," + Buffer.from(file, 'utf-8').toString('base64')}/>
                            }

                            {renderStatus()}

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.input}
                                disabled={!validate()}
                                onClick={() => submit()}>Authenticate</Button>


                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    </>)
}