import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {create} from "../io/Wallet";
import QRCode from "react-qr-code";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    },

    stepper: {
        background: "transparent",
    },

    stepperLabel: {
        background: theme.palette.secondary,
        color: "white"
    },

    form: {
        padding: theme.spacing(5),
        alignContent: "center"
    },

    drawing: {
        padding: theme.spacing(5),
        width: 1000,
        height: 1000,
    },

    input: {
        margin: theme.spacing(1)
    }

}));

export default function AccountGenerator() {

    const classes = useStyles();
    const {address, privateKey } = create()
    const url = `https://www.singularty.io/g?key=${privateKey}`

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>🎨 QRCode</Typography>
                    <Typography className={classes.headitem}>
                        {address}
                    </Typography>

                    <Paper variant="outlined" elevation={3} className={classes.form}>
                        <QRCode value={url}/>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
