var Eth = require('web3-eth');

var Personal = require('web3-eth-personal');

var personal = new Personal(Personal.givenProvider);

new Eth(Eth.givenProvider);

const Web3 = require('web3');
let web3 = new Web3(Web3.givenProvider);

export function connectWallet(response = f => f) {
    if (typeof ethereum !== 'undefined') {
        // eslint-disable-next-line no-undef
        ethereum.enable()
            .then(() => response(true))
            .catch(() => response(false));
    } else {
        response(false);
    }
}

export function getETHAddress() {
    // eslint-disable-next-line no-undef
    return web3.eth.getAccounts();
}

export function sign(from, msg, onSigned) {
    // eslint-disable-next-line no-undef
    personal.sign(msg, from, (e, r) => onSigned(r))
}

export function recover(msg, signature) {
    // eslint-disable-next-line no-undef
    return personal.ecRecover(msg, signature)
}

export function create() {
    return web3.eth.accounts.create();
}

export function getAccountFromPrivKey(key) {
    return web3.eth.accounts.privateKeyToAccount(key)
}
