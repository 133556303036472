import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router";
import React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    title: {
        marginRight: theme.spacing(2),
    },

    navbar: {
        height: "6vh",
        background: "transparent",
        boxShadow: "none"
    }
}));

export default function NavBar() {
    const classes = useStyles();
    const history = useHistory()

    return (
        <div className="App">
            <AppBar position="static" className={classes.navbar}>
                <Toolbar>
                    <Button color="inherit" onClick={() => history.push("/")}>
                        <Typography edge="start" variant="h6" className={classes.title}>
                            Singularty
                        </Typography>
                    </Button>
                    <Button color="inherit" onClick={() => history.push("/robots")}>🔍 Disvover</Button>
                    <Button color="inherit" onClick={() => history.push("/generating")}>🎨 Generate</Button>
                    <Button color="inherit" onClick={() => history.push("/verify-robots")}>🔐 Verify</Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}