export const HUMAN_COLLECTION = 'singularty-io'

function filterTrait(type, asset) {
    return asset.traits.some(t => t.trait_type === type);
}

export function getAssets(type, callback, owner = "") {
    const ownerParam = owner === "" ? "" : `&owner=${owner}`
    fetch(`https://api.opensea.io/api/v1/assets?collection=${HUMAN_COLLECTION}${ownerParam}`,
        {method: 'GET'})
        .then(response => response.json())
        .then(res => res.assets.filter(a => filterTrait(type, a)))
        .then(callback)
        .catch(err => console.error(err));
}

export function getAsset(id, callback) {
    const contract = "0x495f947276749ce646f68ac8c248420045cb7b5e"
    fetch(`https://api.opensea.io/api/v1/asset/${contract}/${id}`,
        {method: 'GET'})
        .then(response => response.json())
        .then(response => callback(response))
        .catch(err => callback(undefined));
}

