import './App.css';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {Route, Switch} from "react-router-dom";

import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Humans from "./pages/Humans";
import React from "react";
import Robots from "./pages/Robots";
import Shipping from "./pages/Shipping";
import Generating from "./pages/Generating";
import ShippingVerify from "./pages/ShippingVerify";
import {RobotsVerify} from "./pages/RobotsVerify";
import AccountGenerator from "./pages/AccountGenerator";
import QrcodeParser from "./pages/QrcodeParser";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: "conic-gradient(from 180deg at 50% 100%, #009688 0%, #3F52B5 50%, #502E8C 100%)",
        color: "white",

    },
    title: {
        marginRight: theme.spacing(2),
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#3f51b5',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#ffbe00',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffffff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

export default function App() {
    const classes = useStyles();

    return (
        <div className="App">
            <div className={classes.root}>
                <ThemeProvider theme={theme}>
                    <NavBar/>
                    <Switch>
                        <Route exact path="/"><Home/></Route>
                        <Route exact path="/humans"><Humans/></Route>
                        <Route exact path="/robots"><Robots/></Route>
                        <Route exact path="/qrcode"><AccountGenerator/></Route>
                        <Route exact path="/g"><QrcodeParser/></Route>
                        <Route exact path="/shipping"><Shipping/></Route>
                        <Route exact path="/verify-shipping"><ShippingVerify/></Route>
                        <Route exact path="/verify-robots"><RobotsVerify/></Route>
                        <Route exact path="/generating"><Generating/></Route>
                    </Switch>
                </ThemeProvider>
            </div>
        </div>
    );
}
