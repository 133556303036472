import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {connectWallet, getETHAddress, sign} from "../io/Wallet";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getAssets} from "../io/OpenSea";
import FormHelperText from "@material-ui/core/FormHelperText";
import MyStepper from "../components/Stepper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    },

    stepper: {
        background: "transparent",
    },

    stepperLabel: {
        background: theme.palette.secondary,
        color: "white"
    },

    form: {
        padding: theme.spacing(5),
        alignContent: "center"
    },

    input: {
        margin: theme.spacing(1)
    }

}));

export default function Shipping() {

    const classes = useStyles();
    const [data, setData] = useState("");
    const stepper = useRef();

    const handleFillForm = (data) => {
        setData(data);
        stepper.current.handleNext();
    }

    const getStepComponent = (curr) => {
        switch (curr) {
            case 0:
                return <ConnectWalletStep onConnectedSuccess={() => stepper.current.handleNext()}/>
            case 1:
                return <FillFormStep onFormFull={(data) => handleFillForm(data)}/>
            case 2:
                return <SendStep data={data}/>
            default:
                return ""
        }
    }

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>📦 Shipping</Typography>
                    <Typography className={classes.headitem}>
                        Singularty use web3 (dApp) technologies to generate your artwork.
                        Therefore you need to connect your wallet to receive your unique artwork.
                    </Typography>

                    <MyStepper ref={stepper} renderStep={getStepComponent}
                               labels={["Connect Wallet", "Fill Form", "Send"]}/>

                </Grid>
            </Grid>
        </div>
    );
}


export function ConnectWalletStep({onConnectedSuccess = f => f}) {
    const classes = useStyles();

    const [status, setStatus] = useState("disconnected");
    const handler = function () {
        connectWallet(res => {
            if (res) {
                onConnectedSuccess();
            } else {
                setStatus("error")
            }
        });

    }

    return (<>
        <Paper variant="outlined" elevation={3} className={classes.form}>
            <Typography className={classes.input}>To prove your ownership, this form will be signed by your wallet. Therefore, firstly connect
                your wallet (the same that content NFT) please.</Typography>
            <Button variant="contained" color="primary" onClick={handler} className={classes.input}>Connect Metamask Wallet</Button>
            {status === "error" ? <Typography color="error">Can't connect your wallet...</Typography> : <></>}
        </Paper>

    </>);

}

export function FillFormStep({onFormFull = f => f}) {
    const classes = useStyles();

    const [assets, setAssets] = useState([]);
    const [token, setToken] = useState("");
    const [name, setName] = useState("");
    const [addr, setAddr] = useState("");
    const [city, setCity] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [comment, setComment] = useState("");
    const [account, setAccount] = useState("zefzef");

    const submit = function () {
        if (validate()) {
            const msg = {
                wallet: account,
                token: token,
                time: (new Date()).getTime(),
                name: name,
                address: addr,
                zipcode: zipcode,
                comment: comment
            }
            sign(account, JSON.stringify(msg), (hash => {
                const data = JSON.stringify({hash: hash, message: msg})
                onFormFull(data);
            }))
        }
    }

    function validate() {
        return assets !== undefined && assets.length !== 0;
    }

    useEffect(() => {
        getETHAddress().then(addr => getAssets("ARTIST", res => {
                setAccount(addr[0])
                setAssets(res)
            }, addr[0]))
    }, [])

    return (<>
        <Paper variant="outlined" elevation={3} className={classes.form}>
            <form noValidate onSubmit={() => submit()} style={{color: "white"}}>
                <TextField
                    disabled="true"
                    variant="outlined"
                    id="account"
                    label="Account"
                    value={account}
                    fullWidth
                    className={classes.input}/>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={!validate()}
                    fullWidth>
                    <InputLabel id="token-label">NFT</InputLabel>
                    <Select
                        labelId="token-label"
                        id="token"
                        value={token}
                        className={classes.input}
                        onChange={(e) => setToken(e.target.value)}
                        label="NFT">
                        {assets.map(asset => <MenuItem key={asset.token_id}
                                                       value={asset.token_id}>{asset.name}</MenuItem>)}
                    </Select>
                    {validate() ? "" : <FormHelperText>Any NFT available</FormHelperText>}
                </FormControl>
                <TextField
                    variant="outlined"
                    id="name"
                    label="Name"
                    value={name}
                    className={classes.input}
                    onChange={(e) => setName(e.target.value)} fullWidth/>

                <TextField
                    variant="outlined"
                    id="addr"
                    label="Address"
                    value={addr}
                    className={classes.input}
                    onChange={(e) => setAddr(e.target.value)} fullWidth/>

                <TextField
                    variant="outlined"
                    id="city"
                    label="City"
                    value={city}
                    className={classes.input}
                    onChange={(e) => setCity(e.target.value)}/>

                <TextField
                    variant="outlined"
                    id="zipcode"
                    label="Zipcode"
                    value={zipcode}
                    className={classes.input}
                    onChange={(e) => setZipcode(e.target.value)}/>

                <TextField
                    variant="outlined"
                    id="comment"
                    label="Comment"
                    value={comment}
                    className={classes.input}
                    fullWidth
                    multiline
                    rows={4}
                    onChange={(e) => setComment(e.target.value)}/>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.input}
                    disabled={!validate()}
                    onClick={() => submit()}>Sign Data</Button>
            </form>
        </Paper>
    </>)
}

export function SendStep({data}) {

    const classes = useStyles();

    const mail = "contact@singularty.io";
    const subject = "Shipping Claim";


    function send() {
        window.open(`mailto:${mail}?subject=${subject}&body=${data}`)
    }

    return (<>
        <Paper variant="outlined" elevation={3} className={classes.form}>
            <form noValidate>
                <TextField
                    disabled
                    variant="outlined"
                    id="to"
                    label="to"
                    defaultValue={mail}
                    fullWidth
                    className={classes.input}/>

                <TextField
                    disabled
                    variant="outlined"
                    id="subject"
                    label="subject"
                    defaultValue={subject}
                    fullWidth
                    className={classes.input}/>

                <TextField
                    disabled
                    variant="outlined"
                    id="data"
                    label="data"
                    defaultValue={data}
                    fullWidth
                    multiline
                    rows={7}
                    className={classes.input}/>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.input}
                    onClick={() => send()}>Send by Mail</Button>
            </form>
        </Paper>
    </>)
}