import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "94vh"
    },

    head: {
        textAlign: "left",
        padding: theme.spacing(3)
    },

    headitem: {
        margin: theme.spacing(3)
    },

    parts: {
        verticalAlign: "top",
    }
}));

export default function Robots() {
    const classes = useStyles();

    const nbCols = Math.max(1, Math.floor(3 * window.innerWidth / window.innerHeight));
    const height = window.innerWidth / nbCols;

    return (
        <div className={classes.root}>
            <Grid container
                  justify="flex-start"
                  alignItems="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={12} sm={6} className={classes.head}>
                    <Typography variant="h3" className={classes.headitem}>🔍 Explore Robot Artworks</Typography>
                    <Typography className={classes.headitem}>
                        Singularty build the bridge from digital artwork to unique and private ownership. We develop algorithm painters only available to NFT owners. Discover algorithm styles or claim your mathematically unique and authenticated artwork !

                    </Typography>

                    <Typography variant="h5" className={classes.headitem}>The Three mandatories parts of ownership</Typography>

                    <table style={{width: "100%"}}>
                        <thead>
                        <th style={{width: "20%"}}></th>
                        <th style={{width: "40%"}}>Singularty 🤖 NFT</th>
                        <th style={{width: "40%"}}>Other NFT for 🐒</th>
                        </thead>
                        <tbody>

                        <tr><td>&nbsp;</td></tr>
                        <tr><td rowSpan="2" className={classes.parts}><b>Abusus</b></td><td colSpan="2"><i>The right to own something, resell it or delete it</i></td></tr>
                        <tr><td>✅ only for you</td><td>✅ only for you</td></tr>

                        <tr><td>&nbsp;</td></tr>
                        <tr><td rowSpan="2" className={classes.parts}><b>Usus</b></td><td colSpan="2"><i>The right to use your asset as you want</i></td></tr>
                        <tr><td>✅ only for you</td><td>❌ for everyone</td></tr>

                        <tr><td>&nbsp;</td></tr>
                        <tr><td rowSpan="2" className={classes.parts}><b>Fructus</b></td><td colSpan="2"><i>The right to make money with your asset use</i></td></tr>
                        <tr><td>✅ enable</td><td>❌ impossible</td></tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>

            <div style={{padding: 30}}/>

            <GridList cellHeight={height} cols={nbCols} className={classes.gridList}>
                    <GridListTile key="harmony" cols={1} rows={1} style={{margin: 20}} onClick={() => window.open("https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/101812588635883555774788317316314456949280700659846641690389931608570205306900")}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="200"
                                    image="/static/bots/drawing4.svg"
                                    title="Harmony"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Harmony Bot
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Harmony Bot draws Harmonograph painting according to equations which emulate the trace of a pen at the end of an oscillated pendulum.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </GridListTile>
            </GridList>

        </div>
    );
}